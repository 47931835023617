<template>
  <div class="order-list">
    <nav-bar
      title="订单"
      @click-left="goBack"
      :left-arrow="isWeixin_status == false"
    />
    <List
      v-model="loading_status"
      :finished="more_status"
      finished-text="没有更多了"
      @load="getOrderList()"
    >
      <div
        class="card"
        v-for="(item, index) in orderlist"
        :key="index"
        @click="goDetail(item.id)"
      >
        <Cell
          :title="'服务项目：' + item.service.name"
          icon="shopping-cart-o"
          size="large"
        >
          <!--  :label="'下单时间:'+ item.create_time" -->
        </Cell>
        <Cell :title="'订单编号：' + item.ordersn">
          <template #label>
            <div class="cardLabel">
              <div>消耗积分：{{ item.score }}</div>
            </div>
          </template>
          <template #right-icon>
            <div class="rightIcon">
              <Tag type="danger" v-if="item.is_use == 0">去使用</Tag>
              <Tag type="primary" v-else>已使用</Tag>
            </div>
          </template>
        </Cell>
      </div>
    </List>
  </div>
</template>
<script>
import washCar from "@/api/washCar";
// import OrderItem from "@components/public/orderItem";
// import OilorderItem from "@components/public/oilorderItem";
import { NavBar, List, Cell, Tag } from "vant";
export default {
  components: {
    NavBar,
    List,
    Cell,
    Tag,
    // OrderItem,
  },
  data() {
    return {
      active: this.$route.query.active || 0,
      orderlist: [],
      limit: 10,
      page: 1,
      radio: "1",
      nowOrdersn: "",
      showModel: false,
      loading_status: false,
      more_status: false,
      show_status: false,
    };
  },
  watch: {
    active: function () {
      this.page = 1;
      this.more_status = false;
      this.orderlist = [];
      this.getOrderList();
    },
  },
  methods: {
    goDetail(id) {
      this.$router.push("/washCarOrderDetail?type=" + id);
    },
    async getOrderList() {
      try {
        const res = await washCar.getStoreServePayIndex(this.page++);
        if (res.code == 200) {
          if (res.data.result.length > 0) {
            for (let i in res.data.result) {
              let obj = res.data.result[i];
              this.orderlist.push(obj);
            }
            this.loading_status = false;
            if (res.data.length == res.total) {
              this.more_status = true;
            }
          } else {
            throw "没有更多了";
          }
        } else {
          throw "暂无数据...";
        }
      } catch (err) {
        this.loading_status = false;
        this.more_status = true;
        console.log(err);
      }
    },
    // 展示退款条件
    showTianjian(ordersn) {
      this.showModel = true;
      this.nowOrdersn = ordersn;
    },
    // 取消订单
    // async outOrder() {
    //   const res = await washCar.getStoreServePayIndex(this.nowOrdersn, this.personList[this.radio], "");
    //   if (res.code == 200) {
    //     this.$toast("退款成功...");
    //     this.showModel = false;
    //   } else {
    //     throw(res.msg);
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.order-list {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: #f4f4f4;
}
.van-list {
  padding: 8px;
}
.card {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 8px;
}
.shopping-list {
  width: 100%;
  height: auto;
  padding-bottom: 40px;
  overflow-y: scroll;
}
.shopping-list-con {
  width: 100%;
  padding-top: 6px;
}
.pay-type {
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: #333333;
  line-height: 45px;
  text-align: center;
  border-bottom: 1px solid #dfdfdf;
}
.remind {
  width: 100%;
  line-height: 20px;
  padding: 4px 10px;
  font-size: 12px;
}
.make-person {
  width: 98%;
  left: 50%;
  height: 50px;
  line-height: 50px;
  margin-left: -49%;
  border-radius: 8px;
}
</style>
