import { post, App_ID } from "./publicHeader";

class washCar {
  // 获取店铺列表
  async getStoreList(page) {
    try {
      const res = await post('/service/user.room/index', {
        app_id: App_ID,
        page,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 店铺详情
  async getStoreDetail(id) {
    try {
      const res = await post('service/user.room/detail', {
        app_id: App_ID,
        id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 店铺服务列表详情
  async getStoreServeList(id) {
    try {
      const res = await post('service/user.index/index', {
        app_id: App_ID,
        store_id: id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 店户端洗车服务下单
  async getStoreServePay(id) {
    try {
      const res = await post('service/user.order/pay', {
        app_id: App_ID,
        service_id: id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 店户端洗车服务订单列表
  async getStoreServePayIndex(data) {
    try {
      const res = await post('service/user.Order/index', {
        app_id: App_ID,
        store_id: data.id,
        page: data.page
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 店户端洗车服务订单详情
  async getStoreServePayDetail(id) {
    try {
      const res = await post('service/user.Order/detail', {
        app_id: App_ID,
        id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new washCar();

